import React,{useState,useEffect} from 'react'
import classes from './Navbar.module.css'
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import TopNavbar from './TopNavbar';
import {useLocation} from 'react-router-dom'
import TermsnConditions from '../Pages/TermsnConditions';
import {useDispatch,useSelector} from 'react-redux'
import SearchSlice, { setSearch } from '../Slices/SearchSlice';
import Cookies from 'js-cookie'
import CloseIcon from '@mui/icons-material/Close';
import {useNavigate,Link} from 'react-router-dom'

const Navbar = ({showModal,closeModal,openModal}) => {

  const [drawer,setDrawer] = useState(false);
  const[openSearch,setOpenSearch] = useState(false);
  const [account,setAccount] = useState(false);

  const navigate = useNavigate();

  const {search} = useSelector((state)=>state.SearchSlice)
 
  // console.log(search,'00000000000')

  const {pathname} = useLocation();
  const dispatch = useDispatch()

  // console.log(pathname)
  

  const closeDrawer=()=>{
    setDrawer(!drawer)
  }

  useEffect(() => {
    
    if (account) {
     
      document.addEventListener('mousedown', closeAccount);
    } else {
      document.body.style.overflow = 'auto'; // Enable background scrolling

    }

    return () => {
      document.removeEventListener('mousedown', closeAccount);
    };
  }, [account]);

  const closeAccount = (event) => {
    if (!event.target.closest(`.${classes.account}`) && !event.target.closest(`.${classes.icon}`)) {
      setAccount(false);
    }
  };

  const handleLogout=()=>{
    Cookies.remove('msisdn')
    navigate('/')
  }


  return (
    <div className={classes.container}>
   {
    (pathname !== "/" ) &&
    <div className={classes.menu}>
    {
    !drawer?
   <MenuIcon fontSize="large" onClick={()=>setDrawer(!drawer)} className={classes.icon} />
   :
   <CloseIcon fontSize="large" onClick={()=>setDrawer(!drawer)} className={classes.icon}/>      
  }
   <TopNavbar drawer={drawer} closeDrawer={closeDrawer}
   openModal = {openModal} 
    
   />
    </div> 
   }
    <div className={classes.logo_div}>
    <img src="/Assets/logo.png" alt="" className={classes.logo} />
    </div>
    {
      pathname !== '/' &&
      <div className={classes.Search}>
      <div>
       <input type="text" placeholder="Search" value={search} className={`${classes.input} ${openSearch && classes.inputopen}`} onChange={(e)=>{dispatch(setSearch(e.target.value))}}/>{" "}
       </div>
       <div className={classes.icons}>
       {/* {!openSearch && */}
       <SearchIcon onClick={()=>setOpenSearch(!openSearch)} fontSize="large" className={classes.icon}/>
       {/* } */}
       <PersonIcon fontSize="large" className={classes.icon} onClick={()=>setAccount(true)}/>
    
       </div>
       <div className={`${classes.userdetails} ${account && classes.account}`}>
       <CloseIcon fontSize="large" className={classes.close_icon} onClick={()=>setAccount(false)}/>
      <div className={classes.text}> <strong>User:</strong> <br />
       
       <p >{Cookies.get('msisdn')}</p></div>
       <hr/>
       <div className={classes.line}></div>
      <Link to='/'> <button className={classes.logOut} onClick={handleLogout}>LogOut</button></Link>
       </div>
    </div>
    }
    <TermsnConditions show={showModal} onClose={closeModal} />
    </div>
  )
}

export default Navbar
