import React,{useEffect,useState} from 'react'
import Layout from '../Layouts/Layout'
import classes from './Home.module.css'
import HighlightsCarousel from '../Components/HighlightsCarousel'
import GamesCarousel from '../Components/GamesCarousel'
import TopNavbar from '../Components/TopNavbar'
import Lottie from 'lottie-react'
import Loader from '../Animation/Loader.json'
import {useSelector,useDispatch} from 'react-redux';
import animation from '../Animation/NoDataFound.json'
import {useLocation} from 'react-router-dom'
import { FetchCategories, FetchGamesByCategory, FetchHighLights, FetchPlayAndWin, FetchTrendingGames } from '../Services/api'
import { clearSearch } from '../Slices/SearchSlice'

const Home = () => {

  const [categories,setCategories] = useState([])
  const [highlights,setHighlights] = useState([]) 
  const [playnwin,setPlaynwin] = useState([])
  const [kidsGames,setKids] = useState([])
  const [adventureGames,setAdventure] = useState([])
  const [sportsGames,setSports] = useState([])
  const [funnyGames,setFunny] = useState([])
  const [actionGames,setAction] = useState([])
  const [racingGames,setRacing] = useState([])
  const [trendings,setTrendings] = useState([])

  const [loading,setLoading] = useState(true);

  const {search} = useSelector(state => state.SearchSlice)

  const {pathname} = useLocation();
  const dispatch = useDispatch()

  const fetchData = async()=>{
    const highlightsResponse = await FetchHighLights();
    setHighlights(highlightsResponse.result);
    
    const trendingResponse = await FetchTrendingGames();
    setTrendings(trendingResponse.result)
    // console.log(trendingResponse)

    const categoriesResponse = await FetchCategories();
    setCategories(categoriesResponse.result);

    const playwinResponse = await FetchPlayAndWin();
    setPlaynwin(playwinResponse.result);

    setLoading(false)
    
    for (let i = 0; i < categoriesResponse?.result?.length; i++) {
      const data = {
        category : categoriesResponse?.result[i]?.cat_name
      }
      // console.log(data)
      const games = await FetchGamesByCategory(data);
      // console.log(games,'-0-----------')
      switch (categoriesResponse?.result[i]?.cat_name) {
        case 'Kids':
          setKids(games.result);
          break;
        case 'Adventure':
          setAdventure(games.result);
          break;
        case 'Sports':
          setSports(games.result);
          break;
        case 'Funny':
          setFunny(games.result);
          break;
        case 'Action':
          setAction(games.result);
          break;
        case 'Racing':
          setRacing(games.result);
          break;
        default:
          console.log(`Unknown category: ${categoriesResponse?.result[i].cat_name}`);
      }
    }


  }

  useEffect(() => {
    fetchData();    
  }, [search])

  useEffect(()=>{
    dispatch(clearSearch())
  },[pathname])

  if(loading){
    return (
      <div className={classes.loader}>
        <Lottie animationData={Loader} className={classes.animation}/>
      </div>
    );
  }


  return (
    <Layout>
    <div className={classes.container}>
    <h2 className={classes.title}>HIGHLIGHTS</h2>
    <HighlightsCarousel data={highlights}/>
    {
      (trendings?.filter((item)=>(item?.gamename.toLowerCase().includes(search?.toLocaleLowerCase()))).length != 0 ||
      playnwin?.filter((item)=>(item?.gamename.toLowerCase().includes(search?.toLocaleLowerCase()))).length  != 0 ||
      kidsGames?.filter((item)=>(item?.gamename.toLowerCase().includes(search?.toLocaleLowerCase()))).length != 0 || 
      adventureGames?.filter((item)=>(item?.gamename.toLowerCase().includes(search?.toLocaleLowerCase()))).length != 0 || 
      sportsGames?.filter((item)=>(item?.gamename.toLowerCase().includes(search?.toLocaleLowerCase()))).length != 0 ||
      funnyGames?.filter((item)=>(item?.gamename.toLowerCase().includes(search?.toLocaleLowerCase()))).length != 0 ||
      actionGames?.filter((item)=>(item?.gamename.toLowerCase().includes(search?.toLocaleLowerCase()))).length != 0 || 
      racingGames?.filter((item)=>(item?.gamename.toLowerCase().includes(search?.toLocaleLowerCase()))).length != 0 )
      ?
      <>
  
    <GamesCarousel data = {trendings?.filter((item)=>(item?.gamename.toLowerCase().includes(search?.toLocaleLowerCase())))} type='TRENDING'/>
    <GamesCarousel data = {playnwin?.filter((item)=>(item?.gamename.toLowerCase().includes(search?.toLocaleLowerCase())))} type='PLAY & WIN'/>
    {
      categories?.map((item,idx)=>(
        <>
          {
            item.cat_name == 'Kids' && kidsGames ? 
            <GamesCarousel data={kidsGames?.filter((item)=>(item?.gamename.toLowerCase().includes(search?.toLocaleLowerCase())))} type={item.cat_name}/>
            :
            item.cat_name == 'Adventure' && adventureGames?
            <GamesCarousel data={adventureGames?.filter((item)=>(item?.gamename.toLowerCase().includes(search?.toLocaleLowerCase())))} type={item.cat_name}/>
            :
            item.cat_name == 'Sports' && sportsGames ?
            <GamesCarousel data={sportsGames?.filter((item)=>(item?.gamename.toLowerCase().includes(search?.toLocaleLowerCase())))} type={item.cat_name}/>
            :
            item.cat_name == 'Funny' && funnyGames?
            <GamesCarousel data={funnyGames?.filter((item)=>(item?.gamename.toLowerCase().includes(search?.toLocaleLowerCase())))} type={item.cat_name}/>
            :
            item.cat_name == 'Action' && actionGames?
            <GamesCarousel data={actionGames?.filter((item)=>(item?.gamename.toLowerCase().includes(search?.toLocaleLowerCase())))} type={item.cat_name}/>
            :
            item.cat_name == 'Racing' && racingGames?
            <GamesCarousel data={racingGames?.filter((item)=>(item?.gamename.toLowerCase().includes(search?.toLocaleLowerCase())))} type={item.cat_name}/>
            :
            ''
          }
        </>
      ))
    }
    </>:
    <div className={classes.noData}>
    <Lottie
      animationData={animation}
      style={{height:"15rem",width:"15rem"}}
    />
      <h2 style={{textAlign:"center"}}>No Search Results!!</h2>
        </div>
    }
   </div>
    </Layout>
  )
}

export default Home
