import React,{useState} from 'react'
import classes from './TopNavbar.module.css'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import {Link,useParams} from 'react-router-dom'
import TermsnConditions from '../Pages/TermsnConditions';


const TopNavbar = ({active,drawer,closeDrawer,openModal}) => {

   const subdomain = 'games'

   const param = useParams();

   const category = param?.category

      const list = (anchor) => (
        <Box
          sx={{ width:'auto'}}
          role="presentation"
          onClick={closeDrawer}
          onKeyDown={closeDrawer}
        >
        <div className={classes.drawer}>
        <Link to={`/redirect/${subdomain}`} className={`${classes.item} ${category == undefined && classes.active}`}>   
        <img src="/Assets/home.png" alt="" />
           <p>Home</p>
        </Link>
        <Link to='/category/play&win' className={`${classes.item} ${category == 'play&win' && classes.active}`}>   
        <img src="/Assets/playwin.png" alt="" />
           <p>Play & Win</p>
        </Link>
        <Link to='/category/Action' className={`${classes.item} ${category == 'Action' && classes.active}`}>   
        <img src="/Assets/action.png" alt="" />
           <p>Action</p>
        </Link>
        <Link to='/category/Adventure' className={`${classes.item} ${category == 'Adventure' && classes.active}`}>   
        <img src="/Assets/adventure.png" alt="" />
           <p>Adventure</p>
        </Link>
        <Link to='/category/Puzzle' className={`${classes.item} ${category == 'Puzzle' && classes.active}`}>   
        <img src="/Assets/puzzle.png" alt="" />
           <p>Puzzle</p>
        </Link>
        <Link to='/category/Sports' className={`${classes.item} ${ category == 'Sports' && classes.active}`}>   
        <img src="/Assets/sports.png" alt="" />
           <p>Sports</p>
        </Link>
        <Link className={`${classes.item} ${ category == 'junior' && classes.active}`}>   
        <img src="/Assets/junior.png" alt="" />
           <p>Junior</p>
        </Link>
        <Link to='/category/Racing' className={`${classes.item} ${category == 'Racing' && classes.active}`}>   
        <img src="/Assets/sports.png" alt="" />
           <p>Racing</p>
        </Link>
        <div  onClick={openModal} className={`${classes.item}`}>   
        <img src="/Assets/tnc.png" alt="" />
           <p>Terms & conditions</p>
        </div>
        <Link className={`${classes.item} `}>   
        <img src="/Assets/recentlyplayed.png" alt="" />
           <p>Recently Played</p>
        </Link>
        <Link className={`${classes.item} `}>   
        <img src="/Assets/privacy.png" alt="" />
           <p>Privacy Policy</p>
        </Link>
        <Link className={`${classes.item} ${active == 12 && classes.active}`}>   
        <img src="/Assets/privacy.png" alt="" />
           <p>Logout</p>
        </Link>
        
       
        </div>
        </Box>
      );


  return (
    <div>
    
      <React.Fragment key={'top'}>
        {/* <Button onClick={toggleDrawer('top', true)}>{'top'}</Button> */}
        <Drawer
          anchor={'top'}
          open={drawer}
          onClose={closeDrawer}
        >
          {list('top')}
        </Drawer>
        
      </React.Fragment>
  </div>
  )
}

export default TopNavbar
