import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import classes from "./GamesCarousel.module.css";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import {Link} from 'react-router-dom'
import {useSelector} from 'react-redux'

const GamesCarousel = ({data,type}) => {

// console.log(data,'000000')
  const {search} = useSelector(state => state.SearchSlice)

  return (
    <>
    {data?.length !== 0  ?
    <div className={classes.container}>
    <h2>{type?.toUpperCase()} GAMES</h2>
    <br />
      <Swiper
        // slidesPerView={5}
        spaceBetween={15}
        pagination={{
          clickable: true,
        }}
        // modules={[Pagination]}
        // className="mySwiper"
        className={classes.swiper}
        breakpoints={{
          320: {
            // When the screen width is >= 320px
            slidesPerView: 3,
            spaceBetween: 10,
          },
          480: {
            // When the screen width is >= 480px
            slidesPerView: 3,
            spaceBetween: 10,
          },
          640: {
            // When the screen width is >= 640px
            slidesPerView: 3,
            spaceBetween: 10,
          },
          768: {
            // When the screen width is >= 768px
            slidesPerView: 4,
            spaceBetween: 15,
          },
          1024: {
            // When the screen width is >= 1024px
            slidesPerView: 5,
            spaceBetween: 15,
          },
        }}
      >
       {
        data?.map((item,idx)=>(
          <SwiperSlide className={classes.swiper_slide}>
        <Link to={item.gameurl} className={classes.link}>
        <img
            src={item.imgurl}
            alt=""
            className={classes.img}
          />
        </Link>
          <p className={classes.name}>{item.gamename}</p>
        </SwiperSlide>
        ))
       }
       
      </Swiper>  
      </div>
      :
      <p style={{fontSize:"1.5rem",fontWeight:"500",color:"#0a86ff"}}></p>
      }
   
    </>
  );
};

export default GamesCarousel;
