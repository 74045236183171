import logo from './logo.svg';
import './App.css';
import {Routes,Route} from 'react-router-dom'
import Home from './Pages/Home';

import CategoryPage from './Pages/CategoryPage';
import TermsnConditions from './Pages/TermsnConditions';
import SdpRedirect from './Pages/SdpRedirect';
import Login from './Pages/Login';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function App() {

    // const subdomain = window.location.hostname.split('.')[0];
    const subdomain='games'
  return (
    <>
    <Routes>
    <Route path= {`/redirect/${subdomain}`} element={<Home/>}/>
    <Route path={'/doiRedirect.jsp'} element={<SdpRedirect/>}/>
    <Route path="/category/:category" element={<CategoryPage/>}/>
    <Route path="/tnc" element={<TermsnConditions/>}/>
    <Route path="/" element={<Login/>}/>
    </Routes>
    {/* <ToastContainer/> */}
    </>
  );
}

export default App;
