import React from 'react'
import classes from './Footer.module.css'

const Footer = () => {
  return (
    <div className={classes.container}>
    2024 © copyright All Right Reserved.
    </div>
  )
}

export default Footer
