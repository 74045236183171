import axios from 'axios'
// const BACKEND_URL = 'http://localhost:5123'
const BACKEND_URL =''

export const FetchCategories=async()=>{
    try{
        const response = await axios.get(`${BACKEND_URL}/api/category`);
        return response.data;
    }
    catch(err){
        return err;
    }
}


export const FetchHighLights=async()=>{
    try{
        const response = await axios.get(`${BACKEND_URL}/api/highlights`);
        return response.data;
    }
    catch(err){
        return err;
    }
}

export const FetchPlayAndWin=async()=>{
    try{
        const response = await axios.get(`${BACKEND_URL}/api/play&win`);
        return response.data;
    }
    catch(err){
        return err;
    }
}

export const FetchGamesByCategory=async(data)=>{
    try{
        const response = await axios.post(`${BACKEND_URL}/api/games`,data);
        return response.data;
    }
    catch(err){
        return err;
    }
}


export const FetchTrendingGames=async(data)=>{
    try{
        const response = await axios.get(`${BACKEND_URL}/api/trendings`);
        return response.data;
    }
    catch(err){
        return err;
    }
}


