import React,{useState,useEffect} from 'react'
import classes from './Layout.module.css'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const Layout = ({children}) => {


  const [showBackToTop, setShowBackToTop] = useState(false);

  const [showModal, setShowModal] = useState(false);
  // console.log(showModal,"++++++++++==============")
    
  const handleOpenModal = () => {
  //  console.log("In Open Modal")
   setShowModal(true);
 };

 const handleCloseModal = () => {
   setShowModal(false);
 };

  useEffect(() => {
    const handleScroll = () => {
      const scrolledPast100vh = window.scrollY > window.innerHeight;
      setShowBackToTop(scrolledPast100vh);
    };

    window.addEventListener('scroll', handleScroll);

    // Initial check on mount
    handleScroll();

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const handleBackToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };


  return (
    <div className={classes.container}>
    <div className={classes.navbar}>
    <Navbar showModal={showModal} closeModal={handleCloseModal} openModal={handleOpenModal}/>
    </div>
    <div>
    {children}
    </div>
    {showBackToTop && !showModal && (
        <div className={classes.backToTopBtn} onClick={handleBackToTop}>
          <ExpandLessIcon /> {'    '} Back To Top
        </div>
      )}
    <div className={classes.footer}>
    <Footer/>
    </div>
    </div>
  )
}

export default Layout
