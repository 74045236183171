import React,{useState} from 'react'
import classes from './Login.module.css'
import Navbar from '../Components/Navbar'
import {Link,useNavigate} from 'react-router-dom'
import Footer from '../Components/Footer'
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import axios from 'axios'
  import Cookies from 'js-cookie'
  import Lottie from 'lottie-react'
  import Loader from '../Animation/Loader.json'

  const subdomain = 'games'

const Login = () => {
    const [msisdn,setMsisdn] = useState('');
    const [checked,setChecked] = useState(false);
    const [loading,setLoading] = useState(false);
console.log("loading",loading)
    const navigate = useNavigate()

    const handleSubmit=async(e)=>{
        e.preventDefault()

        if(!checked){
            toast.warn("Please Read Terms And Condition First!!")
            return;
        }

        
        const data={
            msisdn:msisdn,
            svc_id:43,
            svc_name:'games',
            channel: "WAP",
            doi_channel:"WAP",
        }



       try{
        setLoading(true);

        const response = await axios.post('https://sdpcallback.ndotomedia.com/subscription',data)
        // const response = await axios.post('http://localhost:5040/subscription',data)
        console.log("false console after response",loading)
        // setLoading(false)
        const subid = response.data.result.subscription_id
        const ext = response.data.result.ext_ref
        const svcid = response.data.result.svc_id

        if (response.data.statusId == '1'){
            Cookies.set('msisdn', msisdn, { expires: 1 })
            toast.success('User Login Successfully!!')
            navigate(`/redirect/${subdomain}?msisdn=${msisdn}&result=Active`);
        }
        else{
            window.location.replace(`https://sdp-p-vas-payment.telkom.co.za/${43}?msisdn=${msisdn}&subscription_id=${subid}&ext_ref=${ext}`)
        }
       
       }
       catch(err){
        setLoading(false)
        // console.log("error",err.response)
        // setLoading(false)
             setMsisdn('')
        toast.error('User Not Subscribed!!')
        // console.log("after toast")
       }


      //  console.log('after')

       
  
    }



  return (
    <div className={classes.container}>
 
   <div className={classes.navbar}>
   <img src="/Assets/logo.png" alt="" />
   </div>
   <div className={classes.sub_container}>
   <div className={classes.img}>
   <img src="/Assets/login.jpg" alt="" />
   </div> 
   <form className={classes.form_container} onSubmit={handleSubmit}>
   <p className={classes.welcome}>Welcome To NDOTO Games Portal</p>
   <p className={classes.play}>Play Unlimited! Hundreds of Cool Games</p>
   <p className={classes.enter}>ENTER YOUR NUMBER AND JOIN NDOTO GAMES</p>
   <input type="tel" placeholder="Enter Your Number, eg : 9876******" className={classes.input} onChange={(e)=>setMsisdn(e.target.value)}/>
   <br />
   <input type="checkbox" name="" id="" onChange={()=>setChecked(!checked)} className={classes.check} />{" "}<span className={classes.tnc}>I Agreed to <Link to="/tnc" style={{color:"white",textDecoration:"none",fontWeight:"600"}}>Terms And Conditions</Link></span>

   <br />

   {
    !loading ? 
    <button className={classes.btn} onClick={handleSubmit}>Subscribe</button>
    :
    <Lottie
    animationData = {Loader}
    style={{height:"3rem"}}
    /> 
   }
   </form> 
   </div>
   <ToastContainer/>
   <div className={classes.footer}>
   <Footer/>
   </div>
    </div>
  )
}

export default Login
