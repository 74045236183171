import React,{useEffect} from 'react'
import classes from './TermsnConditions.module.css'
import {useLocation,useNavigate} from 'react-router-dom';

const TermsnConditions = ({show,onClose}) => {

  const location = useLocation()
  const navigate = useNavigate();

  if(location.pathname == '/tnc'){
    show=true;
  }

  useEffect(() => {
    
    if (show) {
      document.body.style.overflow = 'hidden'; // Disable background scrolling
      document.addEventListener('mousedown', onClose);
    } else {
      document.body.style.overflow = 'auto'; // Enable background scrolling

    }

    return () => {
      document.body.style.overflow = 'auto'; // Cleanup when component unmounts
      document.removeEventListener('mousedown', onClose);
    };
  }, [show]);

  const handleClick=()=>{
    if(location.pathname == '/tnc'){
      navigate('/')
      return;
    }
    onClose();
  }


  return (
    <div className={classes.outer}>
    <div className={`${classes.modal} ${show ? classes.show : ''}`}>
    <div className={classes.container}>
      <p className={classes.title}><strong><u>Terms and Conditions of Use</u></strong></p>
      <p className={classes.intro}><span >Ndoto Media is the provider of the &lt;service&gt; platform, in its various formats accessible via mobile and desktop/laptop devices. &lt;service&gt; is a platform that hosts various content services that can be accessed individually by customers based on a customer choosing to subscriber to &lt;service&gt; (&quot;Service(s)&quot;). The terms of this agreement (&quot;Terms and Conditions&quot;) govern the relationship between you and Ndoto Media and its affiliates) (hereinafter &quot;&lt;service&gt;&quot; or &quot;Us&quot; or &quot;We&quot;) regarding your use of &lt;service&gt; and related Services on &lt;service&gt;. Use of &lt;service&gt; is also governed by Privacy Policy which is incorporated herein by reference.</span></p>
      <br />
      <p className={classes.intro}><span>WASPA is a non-profit body, founded in 2004, which represents the interests of mobile application service providers operating in South Africa. WASPA provides a neutral forum for members to address issues of common interest and interact with industry stakeholders, network operators and government bodies.</span></p>
      <br />
      <p className={classes.sub_title}><strong><u><span>Your acceptance of these Terms and Conditions</span></u></strong></p>
      <br />
      <ol start="1" type="1">
        <li className={classes.item}>Please read these Terms and Conditions and the Privacy Policy carefully before using &lt;service&gt; and/or any of the services therein. By using &lt;service&gt; and/or any of Services therein or submitting information on &lt;service&gt;, you are agreeing to these Terms and Conditions. If you do not accept these Terms and Conditions, please do not use &lt;service&gt; or any Services therein.</li>
        <li className={classes.item}>We reserve the right, at any time, to modify and update these Terms and Conditions by posting such updated Terms and Conditions on Ndoto Media and &lt;service&gt; website. Any such updates shall apply to you and your use of &lt;service&gt; and the Services therein. By continuing to use &lt;service&gt;, you shall be deemed to have accepted such changes.</li>
        <li className={classes.item}>You can access &lt;service&gt; on URL: https://games.ndotomedia.com or on the Ndoto Media website under the services banner to access &lt;service&gt;. To access and use the &lt;service&gt; you must be 13 (Thirteen) years of age or older.</li>
        <li className={classes.item}>Once registered to use &lt;service&gt;, you give us consent to provide you with various notifications and messages about / based on your interaction with the Service. These notifications will be delivered to you in the form of text messages. These messages include, but are not limited to:
        <ol start="1" type="a" style={{marginLeft:"1.5rem"}}>
                <li className={classes.item}>Periodic messages to encourage you to browse or access Services on &lt;service&gt;, or inform you of various features, new or existing;</li>
                <li className={classes.item}>Messages inviting you to try alternate versions of the Service based on our knowledge of the device you are using to access &lt;service&gt;;</li>
                <li className={classes.item}>Confirmation messages relating to your registration or deregistration for the Service, and/or when you purchase / subscribe / unsubscribe to any premium services available on the Service.</li>
            </ol>
        </li>
        <li className={classes.item}>We grant you permission to use &lt;service&gt; and its Services, in accordance with these Terms and Conditions, provided that:
            <ol start="1" type="a" style={{marginLeft:"1.5rem"}}>
                <li className={classes.item}>your use of &lt;service&gt; is only for your personal, non-commercial purposes;</li>
                <li className={classes.item}>you do not copy or distribute any part of &lt;service&gt; in any medium;</li>
                <li className={classes.item}>you do not modify or reverse engineer any part of &lt;service&gt;; and</li>
                <li className={classes.item}>you comply with these Terms and Conditions.</li>
            </ol>
        </li>
      </ol>

<br />
      <div >
    <ol>
        <li ><span className={classes.item}>Ndoto Media is a member of WASPA and is bound by the WASPA Code of Conduct. Customers have the right to approach WASPA to lodge a complaint in accordance with the WASPA complaints procedure. Ndoto Media may be required to share information relating to a service or a customer with WASPA for the purpose of resolving a complaint. WASPA web site: www.waspa.org.za &lt;service&gt; is a subscription service charged at R3/day. By using the service you agree that you are 18 years or older and have the bill payers permission. Compatible handsets only. To unsubscribe dial USSD *179#. For help call +27 (63) 686-7331. By using the service you agree to receive promotional material relating to this and similar services. To opt-out of marketing dial USSD *179#. Service provided by NdotoMedia, Fernbrook Estate 20 Brookside Crescent Inchanga Road , Maroeladal 2191.</span></li>
    </ol>
</div>
{/* <p className={classes.item} style={{marginLeft:"1.5rem"}}>&nbsp;</p> */}
<p className={classes.item} style={{marginLeft:"1rem"}}><span>This service must not be used:</span><span><br/>&nbsp;</span></p>
<p className={classes.item} style={{marginLeft:"1rem"}}><span >&nbsp; &nbsp; (i) to intentionally engage in illegal conduct,</span><span ><br/>&nbsp;</span></p>
<p className={classes.item} style={{marginLeft:"1rem"}}><span >&nbsp; &nbsp; (ii) to knowingly create, store or disseminate any illegal content,</span><span ><br/>&nbsp;</span></p>
<p className={classes.item} style={{marginLeft:"1rem"}}><span >&nbsp; &nbsp; (iii) to knowingly infringe copyright,</span><span><br/>&nbsp;</span></p>
<p className={classes.item} style={{marginLeft:"1rem"}}><span >&nbsp; &nbsp; (iv) to knowingly infringe any intellectual property rights, or</span><span ><br/>&nbsp;</span></p>
<p className={classes.item} style={{marginLeft:"1rem"}}><span>&nbsp; &nbsp;   (v) to send spam or promote the sending of spam.&nbsp;</span></p>
<p className={classes.item} style={{marginLeft:"1rem"}}><span >We have the right to suspend or terminate the services of &nbsp;any &nbsp;customer who does not comply with these terms and conditions or any other related contractual obligations, and we have right to take down any content (hosted as part of the service) that it considers illegal or for which it has received a take-down notice.</span></p>

<ol start="7" type="1" >
    <li className={classes.item}>You may not use, store, display, publish, transmit, distribute, modify, reproduce, create derivative works of or in any way exploit &lt;service&gt;, in whole or in part, outside of the specific usage rights granted to you by Ndoto Media.</li>
    <li className={classes.item}>We may change, suspend or discontinue all or any aspect of &lt;service&gt; at any time, including the availability of any specific features. In such event, we shall not be required to provide benefits or other compensation to Subscribers in connection with such discontinued live, or any discontinued parts thereof.</li>
    <li className={classes.item}>You acknowledge that from time to time, &lt;service&gt;&apos;s availability may be limited due to maintenance, upgrade or other technical reasons as relevant.</li>
    <li className={classes.item}>To the extent allowed by law, &lt;service&gt; is provided on an &quot;as is&quot; and &quot;as available&quot; basis for your use, without warranties of any kind, express or implied. We do not warrant that you will be able to access or use &lt;service&gt; on any device, or any time or location, or that &lt;service&gt; will be uninterrupted or error-free. You agree that we will not be liable to you or to any third party for any unavailability, modification, suspension or withdrawal of any Content, or any features, part of or Content of &lt;service&gt; or failure to make available any advertised Content title or to do so at the advertised time.</li>
    <li className={classes.item}>You acknowledge that we may appoint third party companies and individuals to facilitate the provision of &lt;service&gt;. These third parties have limited access to your information only to the extent necessary for the performance of &lt;service&gt; and not to disclose or use it for other purposes.</li>
    <li className={classes.item}>These Terms and Conditions apply to all users of &lt;service&gt; and the Services therein, including users who are also contributors of information, and other materials or &lt;service&gt;. &lt;service&gt; may also contain third party advertisements, content, materials, and/or links to third party websites that are not owned or controlled by us. We assume no responsibility for, the content, privacy policies or practices of any third party websites. By using our &lt;service&gt;, you expressly release us from any and all liability arising from your use of any third party website or as a result of any third party presence on our &lt;service&gt;.</li>
    <li className={classes.item}>You agree to use the &lt;service&gt; in good &lt;service&gt; and in accordance with all applicable laws. Refraining from using the &lt;service&gt; for illegal purposes, or in a manner that is contrary to any applicable laws, harms the rights and/or interests of others, or in any way damages, disables Terms, or overburdens or impairs &lt;service&gt;.</li>
    <li className={classes.item}>You shall abstain from obtaining or attempting to obtain information, messages, graphics, pictures, sound files and / or images, photographs, recordings, software and generally any kind of material accessible using means or other than that which the &lt;service&gt; provides.</li>
</ol>
<br />
<p className={classes.sub_title}><strong><u><span >Fees and Payment Terms</span></u></strong></p>

<ol start="15" type="1" >
    <li className={classes.item}>You acknowledge and accept that a Service(s) require payment on a once-off or subscription basis, as applicable.</li>
    <li className={classes.item} >Depending on the chosen payment option for the Service(s) you may be billed ad-hoc, daily, weekly, or monthly for specific Service(s) that you register/subscribe to for &lt;service&gt;. Subscription and usage is voluntary and occurs via the Service. The charges for the Services is subject to change, at Ndoto Media&apos;s, or the relevant third party content owner&apos;s discretion. Any changes therein will be communicated to subscribers with reasonable notice and you will be afforded the option to unsubscribe.</li>
    <li className={classes.item}>Payments for the Service(s) will be levied against the payment method of your choice on the Service.<ol start="1" type="a" style={{marginLeft:"1.5rem"}}>
            <li className={classes.item}>When paying via your mobile telephone bill, the charge will be taken from your prepaid airtime balance (for TopUp and prepaid users), or added to your account (for postpaid users).</li>
            <li className={classes.item}>No refunds will be allocated for partial use of the Service</li>
        </ol>
    </li>
    <li className={classes.item}>You will always be advised of the fees payable for the Service and you must first accept the cost prior to subscribing.</li>
    <li className={classes.item}>When using games content, you acknowledge that the quality of the playback of the content may vary from device to device, and may be affected by a variety of factors, such as your location, the bandwidth available through and/or speed of your Internet connection. Not all content is available in all formats. You are responsible for all Internet access charges.</li>
</ol>
<br />
<p ><strong><u><span >Limitation of Liability</span></u></strong></p>
<ol start="20" type="1">
    <li className={classes.item}>You agree that your use of &lt;service&gt; and its Services is at your sole risk. To the fullest extent permitted by law, we and our officers, directors, employees, and agents disclaim all warranties, express or implied, in connection with &lt;service&gt; and your use thereof. We make no warranties or representations about the accuracy or completeness of &lt;service&gt; Services content or the content of any websites or Service linked to &lt;service&gt; and assume no liability or responsibility for any:
    <ol start="1" type="a" style={{marginLeft:"1.5rem"}}>
            <li className={classes.item}>errors, mistakes, or inaccuracies of content;</li>
            <li className={classes.item}>personal injury or property damage of any nature whatsoever resulting from your access to and use of &lt;service&gt; or its Services;</li>
            <li className={classes.item}>any unauthorized access to or use of our servers and/or any and all personal information and/or financial information stored therein;</li>
            <li className={classes.item}>any interruption or cessation of transmission to or from &lt;service&gt;;</li>
            <li className={classes.item}>any bugs, viruses, trojan horses or the like which may be transmitted to or through &lt;service&gt; by any third party; or</li>
            <li className={classes.item}>any errors or omissions in any content or for any loss or damage of any kind incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available via &lt;service&gt;. We do not warrant, endorse, guarantee, or assume responsibility for any product or &lt;service&gt; advertised or offered by a third party through &lt;service&gt; or any hyperlinked website or featured in any banner or other advertising, and we will not be a party to or in any way be responsible for monitoring any transaction between you and third-party providers of products or &lt;service&gt;.</li>
        </ol>
    </li>
    <li className={classes.item}>To the extent permitted by applicable law, in no event shall we or our subsidiaries and affiliates, and their respective officers, directors, owners, agents, employees, representatives and licensors be liable for any special, incidental, indirect, consequential, punitive or exemplary losses or damages whatsoever or for loss of profits (including, without limitation, damages for loss of revenue, loss of data, failure to realize expected savings, interruption of activities, or any other pecuniary or economic loss) and whether arising from breach of contract, damages (including negligence), strict liability or otherwise, arising out of the use of or inability to use &lt;service&gt; or its content, or any Service described or provided through the &lt;service&gt;.</li>
    <li className={classes.item}>Save to the extent permitted by law, you agree to indemnify, defend and hold harmless Ndoto Media from and against any and all claims, demands, actions, liability, losses, costs and expenses (including legal fees and expenses) arising from or related to:
    <ol start="1" type="a" style={{marginLeft:"1.5rem"}}>
            <li className={classes.item}>any use or alleged use of the &lt;service&gt; by any other person, whether or not authorized by you;</li>
            <li className={classes.item}>your breach of these Terms and Conditions, including any breach of your representations and warranties herein; and</li>
            <li className={classes.item}>your violation of any law; or</li>
            <li className={classes.item}>your use of or inability to use the &lt;service&gt;, the contents, any hyperlinked website, and any of the Services made available on &lt;service&gt;.</li>
        </ol>
    </li>
</ol>
<br />
<p className={classes.sub_title}><strong><u><span >Privacy and data protection</span></u></strong></p>

<ol  start="23" type="1">
    <li className={classes.item}>For the purpose of &lt;service&gt; we will collect information on your MSISDN, name and preferences. This information will be shared with Genrosys Technologies (Pty) Ltd, our appointed service provider for &lt;service&gt; and maybe transferred across the border in order to enable the provision of &lt;service&gt; and its various Services. The Ndoto Media&apos;s Privacy Policy shall apply in addition to these terms and conditions.</li>
</ol>

<p className={classes.item}><span >List of Services currently on &lt;service&gt; and that may be subscribed to individually and are subject to additional terms and conditions that are available on &lt;service&gt; as part of the Service registration/subscription process.</span></p>

<br />

<ol start="24" type="1" >
    <li className={classes.item}><strong><u>Games on Demand Service</u></strong>
        <ol start="1" type="a" style={{marginLeft:"1.5rem"}}>
            <li className={classes.item}>This is a recurring daily, weekly or monthly subscription to games channels whereby a consumer can play unlimited games content per channel.</li>
            <li className={classes.item}>In the event that Ndoto Media is unable to bill for the full subscription fee for this Service, Ndoto Media will implement micro-billing. Micro billing occurs when a user that is subscribed or subscribing to the Service does not have sufficient funds in their account (airtime balance) to pay for the service. Micro-billing will allow for Ndoto Media to charge a discounted subscription in the event that the user&apos;s airtime balance is less than the subscription fee.</li>
        </ol>
    </li>
</ol>

<br />
<p className={classes.title}><strong><u><span>How to opt-out</span></u></strong></p>

<ol start="25" type="1" >
    <li className={classes.item}>If you want to opt out of Services on &lt;service&gt;, you can dial *135*997# to manage all your content services.</li>
    <li className={classes.item}>Please note you may not opt out of system messages sent to the customer when subscribing, cancelling the live or receiving renewal notifications of their next billing cycle.</li>
</ol>
<br />
<br />
<div className={classes.btn_div}><button className={classes.btn} onClick={handleClick}>OK</button>
</div>
</div>


</div>
</div>
  )
}

export default TermsnConditions

