import {createSlice} from '@reduxjs/toolkit'

const initialState = {search:""};

const SearchSlice = createSlice({
    name:'SearchSlice',
    initialState:initialState,
    reducers:{
        setSearch:(state,action)=>{
            state.search = action.payload;
            return state;
        },
        clearSearch:(state,action)=>{
            state.search = '';
            return state;
        }
    }
})


export const {setSearch,clearSearch} = SearchSlice.actions;
export default SearchSlice.reducer