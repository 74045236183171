import React from 'react'
import classes from './HighlighCarousel.module.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import {useNavigate,Link} from 'react-router-dom'

const HighlightsCarousel = ({data}) => {
  // console.log(data)

  

  // const handleSlideClick=(path)=>{
  //   window.location.href = path
  // }

  return (
    <div>
     <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        dots={false}
        navigation={false}
        modules={[Autoplay, Navigation]}
        className="mySwiper"
      >
        {
          data?.map((item,idx)=>(
            
            <SwiperSlide>
            <Link to={item.gameurl}>
            <img src={item.imgurl} alt="" className={classes.img} />
            </Link>
            </SwiperSlide>
            
          ))
        }
      </Swiper>
    </div>
  )
}

export default HighlightsCarousel
