import axios from 'axios';
import React, { useState,useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
// import Loading from './Loading'
// import { baseUrl } from '../data/data';
// import MainLoader from './MainLoader';
import Cookies from 'js-cookie'
import Lottie from 'lottie-react'
import Loader from '../Animation/Loader.json'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

// import { baseUrl } from '../data/data';

// const baseUrl = 

const SdpRedirect = () => {
    const [loading, setLoading] = useState(true);
    const [initialloading,setInitialLoading]=useState(true)
    const [q] = useSearchParams();
    const navigate = useNavigate();
    const subdomain='games'

    useEffect(() => {
    check();
      }, []);
    
    // const svc_id=55
    let msisdn = q.get("msisdn");
    // let svc_id=q.get("svc_id")
  let ext_ref = q.get("ext_ref");

    let subscription_id = q.get("subscription_id");

    // console.log("redirect data", msisdn, subscription_id);

   const check=async()=>{
   await axios.get(`https://sdpcallback.ndotomedia.com/check-sub?msisdn=${msisdn}&subscription_id=${subscription_id}&svc_name=${subdomain}`)
// await axios.get(`http://localhost:5040/check-sub?msisdn=${msisdn}&subscription_id=${subscription_id}&svc_name=${subdomain}`)
.then(resp => {
        const data = resp.data;
        console.log("check-data =>", data);
        setLoading(false);
        setInitialLoading(false);
        if (data.result.length >0 || data.statusId =='1') { 
           console.log(data.statusId,'---------------')
            Cookies.set('msisdn',msisdn)
            
                toast.success('Subscriber Exists!!')
                navigate(`/redirect/${subdomain}?msisdn=${msisdn}&result=Active`);
      
        } 

        if (data.statusId == '0'){
            // toast.error('No Subcriber Exist!!')
            console.log(data.statusId,'-------------------------')
            // toast.error('No Subcriber Exist!!')
       
            navigate('/')
    
            // window.location.replace(`https://sdp-s-vas-payment.telkom.co.za/${svc_id}?msisdn=${msisdn}&subscription_id=${subscription_id}&ext_ref=${ext_ref}`)
        }

       
    })
    .catch(e => {
        setLoading(false);
        console.log('in catch')
        navigate("/");
    });
   }

        console.log("loading",loading)
    if (initialloading) return ( <div style={{height:"100vh",backgroundColor:"black"}}>
    <Lottie
    style={{height:"100vh"}}
        animationData={Loader}
    />
    
    </div>)
    return <ToastContainer/>;
};

export default SdpRedirect;


