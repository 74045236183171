import React,{useEffect,useState} from 'react'
import classes from './CategoryPage.module.css'
import Layout from '../Layouts/Layout'
import {useParams,useLocation} from 'react-router-dom'
import {FetchGamesByCategory,FetchPlayAndWin} from '../Services/api'
import {Link} from 'react-router-dom'
import {useSelector,useDispatch} from 'react-redux'
import { clearSearch, setSearch } from '../Slices/SearchSlice'
import Lottie from 'lottie-react'
import animation from '../Animation/NoDataFound.json'

const CategoryPage = () => {
    const params = useParams();
    const [gameData,setGameData] = useState([]);
    const {pathname} = useLocation();
    const dispatch = useDispatch()


    // console.log(params.category)

    const {search} = useSelector(state => state.SearchSlice)
    console.log(search)

    useEffect(()=>{
      dispatch(clearSearch())
    },[params,pathname])

    

    const fetchData=async()=>{
        const data = {
            category:params.category
        }
        const games = await FetchGamesByCategory(data)
        setGameData(games.result?.filter((item)=>(item?.gamename.toLowerCase().includes(search?.toLocaleLowerCase()))))

        if(params.category == 'play&win'){
            const games = await FetchPlayAndWin();
            setGameData(games.result?.filter((item)=>(item?.gamename.toLowerCase().includes(search?.toLocaleLowerCase()))))
        }
    }

    useEffect(() => {
       fetchData()
       
    }, [params,search])


   

    // console.log(gameData)

  return (
    <Layout>
   <div className={classes.container}>
  <h1 className={classes.title}>{params.category} Games</h1>
  {

 gameData?.length > 0 ? 
  <div className={classes.games}>
 {
    gameData?.map((item,idx)=>(
        <Link to={item.gameurl} className={classes.item}>
            <img src={item.imgurl} alt="" className={classes.img} />
            <p className={classes.name}>{item.gamename}</p>
        </Link>
   ))
  
 }
  
  
  </div>
  :
  <div className={classes.noData}>
    <Lottie
      animationData={animation}
      style={{height:"15rem",width:"15rem"}}
    />
      <h2 style={{textAlign:"center"}}>No Games Found!!</h2>
        </div>
  }
   </div>
    </Layout>
  )
}

export default CategoryPage
